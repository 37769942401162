<template>
  <el-dialog
    class="timeslot-left-dialog"
    title="Внимание"
    :visible.sync="dialogVisible"
    width="710px"
    :before-close="close"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <!--    <div>-->
    <!--      На вашем балансе-->
    <!--      <span style="color: #4ca7d8; font-weight: 700">-->
    <!--        {{ timeslotsLeft }}-->
    <!--      </span>-->
    <!--      таймслотов<span v-if="!addBalaceRequest">-->
    <!--        , для бронирования-->
    <!--        <span style="color: #4ca7d8; font-weight: 700">-->
    <!--          {{ requestsCount }}-->
    <!--        </span>-->
    <!--        таймслотов необходимо оплатить еще-->
    <!--        <span style="color: #f56c6c; font-weight: 700">-->
    <!--          {{ requestsCount - timeslotsLeft - 3 }}-->
    <!--        </span>-->
    <!--      </span>-->
    <!--    </div>-->

    <div class="nnz">
      <div class="nnz__title">Воспользуйтесь новым приложением!</div>
      <div class="nnz__subtitle">
        Бронирование таймслотов и прочие операции (перенос, удаление) теперь
        осуществляются в новом приложении. Там же доступен функционал пополнения
        баланса.
      </div>
      <div class="nnz__list">
        1. Пополняете свой баланс в разделе “Профиль - Пополнить“
      </div>
      <div class="nnz__list">
        2. Оплата доступна - через СБП, по счету и по карте.
      </div>
      <div class="nnz__list">
        3. Зачисленные средства будут списываться с вашего баланса при
        бронировании таймслота
      </div>
      <div class="nnz__list">
        4. Список пополнений и списаний будет виден в списке операций в вашем
        профиле в новом приложении.
      </div>
      <div class="nnz__list">
        5. В разделе "Таймслоты" можно забронировать новый таймслот и посмотреть
        список всех своих таймслотов.
      </div>
      <div class="nnz__help">
        В случае проблем обращайтесь в поддержку - 8 800 250 75 31
      </div>
      <div class="nnz__help">
        Все таймслоты уже зачислены на баланс вашего нового приложения!
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <div class="timeslots-left__footer">
        <!--        <iq-button full-width color="gray" size="small" @onClick="close">-->
        <!--          Отменить-->
        <!--        </iq-button>-->
        <iq-button full-width type="primary" size="small" @onClick="submitNNZ">
          Перейти в приложение для бронирования
        </iq-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ENV } from '@/constants/env'
import { getLoginToken } from '@/core'
import IqButton from '../../ui/components/buttons/IqButton'

export default {
  components: { IqButton },
  props: {
    timeslotsLeft: {
      type: Number,
      required: true,
    },
    requestsCount: {
      type: Number,
      required: true,
    },
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    addBalaceRequest: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    submitNNZ() {
      const token = getLoginToken()

      window.location.href = `${ENV.NNZ_URL}/timeslots/get?token=${token}`
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="sass">
$bg-modal: rgba(225, 225, 225, 0.82)
$bg-modal-header: #4CA7D8
$white: #fff
.timeslot-left-dialog
  background: $bg-modal
  .el-dialog
    border-radius: 4px!important
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1) !important
    @media(max-width: 1120px)
      width: 50%!important
    @media(max-width: 768px)
      width: 80%!important
    @media(max-width: 480px)
      width: 90%!important
  .el-dialog__body
    padding: 12px 15px!important
  .dialog-footer
    display: flex
    flex-direction: column
    flex-wrap: nowrap
    align-items: center
    justify-content: space-between
    @media(max-width: 420px)
      flex-wrap: wrap
  .el-button
    @media(max-width: 1099px)
      margin-left: 0px!important
      margin-bottom: 10px!important
      margin-top: 10px!important
  .el-dialog__headerbtn
    top: 13px!important
    right: 10px!important
  .el-dialog__close
    font-size: 24px!important
    color: $white!important
  .el-dialog__header
    background: $bg-modal-header!important
    padding: 12px 15px!important
    border-radius: 4px 4px 0px 0px!important
    > span
      font-style: normal!important
      font-weight: 500!important
      font-size: 18px!important
      line-height: 26px!important
      color: $white!important

.timeslots-left__footer
  display: flex
  justify-content: center
  align-items: center
  margin: 12px 15px 0px
  width: 100%
  @media (max-width: 1199px)
    flex-direction: column
    margin: 12px 0 0

.nnz
  margin: 24px 0
  &__title
    margin-bottom: 16px
    font-size: 24px
    font-weight: 700
  &__subtitle
    margin-bottom: 16px
    font-size: 18px
    font-weight: 500
  &__list
    padding-left: 16px
    font-size: 16px
  &__help
    margin-top: 16px
    font-size: 16px
    font-weight: 700
</style>
