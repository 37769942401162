var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "timeslot-left-dialog",
      attrs: {
        title: "Внимание",
        visible: _vm.dialogVisible,
        width: "710px",
        "before-close": _vm.close,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "nnz" }, [
        _c("div", { staticClass: "nnz__title" }, [
          _vm._v("Воспользуйтесь новым приложением!"),
        ]),
        _c("div", { staticClass: "nnz__subtitle" }, [
          _vm._v(
            " Бронирование таймслотов и прочие операции (перенос, удаление) теперь осуществляются в новом приложении. Там же доступен функционал пополнения баланса. "
          ),
        ]),
        _c("div", { staticClass: "nnz__list" }, [
          _vm._v(" 1. Пополняете свой баланс в разделе “Профиль - Пополнить“ "),
        ]),
        _c("div", { staticClass: "nnz__list" }, [
          _vm._v(" 2. Оплата доступна - через СБП, по счету и по карте. "),
        ]),
        _c("div", { staticClass: "nnz__list" }, [
          _vm._v(
            " 3. Зачисленные средства будут списываться с вашего баланса при бронировании таймслота "
          ),
        ]),
        _c("div", { staticClass: "nnz__list" }, [
          _vm._v(
            " 4. Список пополнений и списаний будет виден в списке операций в вашем профиле в новом приложении. "
          ),
        ]),
        _c("div", { staticClass: "nnz__list" }, [
          _vm._v(
            ' 5. В разделе "Таймслоты" можно забронировать новый таймслот и посмотреть список всех своих таймслотов. '
          ),
        ]),
        _c("div", { staticClass: "nnz__help" }, [
          _vm._v(
            " В случае проблем обращайтесь в поддержку - 8 800 250 75 31 "
          ),
        ]),
        _c("div", { staticClass: "nnz__help" }, [
          _vm._v(
            " Все таймслоты уже зачислены на баланс вашего нового приложения! "
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "div",
            { staticClass: "timeslots-left__footer" },
            [
              _c(
                "iq-button",
                {
                  attrs: { "full-width": "", type: "primary", size: "small" },
                  on: { onClick: _vm.submitNNZ },
                },
                [_vm._v(" Перейти в приложение для бронирования ")]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }